<template>
  <div></div>
</template>

<script>
import { initialAbility } from '@/plugins/acl/config'
import AuthApi from '@/api/auth'

export default {
  mounted() {
    // Remove userData from localStorage
    // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    const userInfoJson = localStorage.getItem('userInfo')
    if (userInfoJson) {
      const user = JSON.parse(userInfoJson)
      AuthApi.logout(`Bearer ${user.accessToken}`).then(res => {

      })
    }
    localStorage.removeItem('userInfo')

    // Remove userData & Ability from localStorage
    localStorage.removeItem('userData')
    localStorage.removeItem('userAbility')

    // Reset ability
    this.$ability.update(initialAbility)

    // Redirect to login page
    this.$router.push('/login')
  },
  methods: {
    removeAllItem() {

    },
  },
}
</script>
